import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Navbar } from "../../components/Navbar";

import BlogHero from "../../components/BlogHero";
import Leut from "../../components/Leut";
import Pasara from "../../components/Pasara";
import Batana from "../../components/Batana";
import Gajeta from "../../components/Gajeta";

const BlogPage = () => {
  const { t } = useTranslation("blog");
  return (
    <div className="relative bg-black text-white">
      <Helmet>
        <title>{t("blog:metaTitle")}</title>
        <meta name="description" content={t("blog:metaDescription")} />
        <meta name="author" content="Edo Opanovic" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="Edo Opanović, blog, tradicionalni brodovi, drveni brodovi, leut, batana, gajeta, dalmacija, vizure, pasara, more, more i brodovi, brodovi, brodovi dalmacija, brodovi hrvatska, brodovi jadran, brodovi mediteran, brodovi jadransko more, brodovi sredozemno more, brodovi dalmatinski otoci, brodovi dalmatinska obala"
        />

        <link rel="canonical" href="https://dalmatinske-vizure.com/blog" />
      </Helmet>
      <div className="fixed top-0 left-0 w-full z-50">
        <Navbar />
        <div className="flex items-center justify-between mx-0 h-[45px] bg-[#656565]">
          <h1 className="font-light text-2xl lg:ml-[90px] md:ml-10 ml-6 text-white">
            Blog
          </h1>
        </div>
      </div>
      <div className="mt-[15px]">
        <BlogHero />
        <Leut />
        <Pasara />
        <Batana />
        <Gajeta />
      </div>
    </div>
  );
};

export default BlogPage;
