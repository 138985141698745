import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Navbar } from "../../components/Navbar";

import { MailOutline, PlaceOutlined } from "@mui/icons-material";

const ContactPage = () => {
  const { t } = useTranslation("contact");
  return (
    <section className="bg-black overflow-hidden font-normal">
      <Helmet>
        <title>{t("contact:metaTitle")}</title>
        <meta name="description" content={t("contact:metaDescription")} />
        <meta name="author" content="Edo Opanovic" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="Edo Opanovic, Biograd na Moru, Hrvatska, Jadranska obala, Dalmacija, Digitalna Umjetnost, Galerija Slika, Likovna umjetnost, moderna umjetnost, suvremena tradicionalna umjetnost "
        />
        <meta
          name="keywords"
          content="Edo Opanovic, Biograd na Moru, Croatia, adriatic shoreline, dalmatia, digital art, art gallery, fine art, modern art, Contemporary-Traditional Art"
        />
        <link rel="canonical" href="https://dalmatinske-vizure.com/contact" />
      </Helmet>
      <div className="w-full h-full bg-black text-white relative">
        <Navbar />

        <div className="flex items-center justify-between mx-0 h-[45px] bg-[#656565]">
          <h1 className="font-light text-2xl lg:ml-[90px] md:ml-10 ml-6 text-white">
            {t("contact:contact")}
          </h1>
        </div>

        <div className="w-full flex items-center justify-center flex-col">
          <h1 className="font-light text-white text-center mt-10 text-4xl uppercase">
            {t("contact:contact")}
          </h1>
          <img
            src="/traka.png"
            alt="Traka"
            className="mx-auto w-[30%] md:w-[14%]"
          />
          <h1 className="font-light uppercase text-4xl mt-2">
            {t("contact:artist")}
          </h1>
        </div>

        <section className="bg-black h-full flex items-center justify-between flex-col md:flex-row">
          <div className="md:w-1/2 md:mr-8 max-w-full ">
            <img
              src="/edo.svg"
              alt="Edo Opanovic"
              width={400}
              height={400}
              className="border-none max-w-full md:ml-24"
            />
          </div>
          <div className="md:w-[64%] md:ml-8 z-10">
            <div className="flex flex-col items-center md:items-start space-y-10 mt-8 mb-5 md:mt-0">
              <div className="flex flex-col items-start justify-center md:items-start space-y-10 mt-8 mb-5 md:mt-0">
                <div className="flex items-center justify-center space-x-2">
                  <p className="uppercase">Edo Opanovic</p>
                </div>
                <div className="flex items-center justify-center space-x-2">
                  <div>
                    <div className="flex flex-row items-center justify-center">
                      <PlaceOutlined className="w-5 h-5 mr-2" />
                      <p>{t("contact:address")}</p>
                    </div>
                    <p className="ml-7">{t("contact:address2")}</p>
                    <p className="ml-7">{t("contact:address3")}</p>
                  </div>
                </div>

                <div className="flex items-center justify-center space-x-2">
                  <MailOutline className="w-5 h-5" />
                  <p>Email:</p>
                  <a href="mailto: info@fine-art-of-dalmatia.com">
                    info@fine-art-of-dalmatia.com
                  </a>
                </div>
                <div className="flex items-center justify-center space-x-2">
                  <MailOutline className="w-5 h-5" />
                  <p>
                    E-mail:{" "}
                    <a href="mailto:info@dalmatinske-vizure.com">
                      info@dalmatinske-vizure.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="ml-8 absolute top-[100px] left-[45%] z-0 hidden md:block overflow-hidden">
          <img
            src="/karta.svg"
            alt="Karta"
            className="border-none mt-10 w-[750px] md:ml-8 2xl:mt-0 "
          />
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
