import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Navbar } from "./Navbar";

export default function BestWork() {
  const { t } = useTranslation("home");
  return (
    <div className="mx-auto flex flex-col bg-black w-full h-full lg:w-screen text-white">
      <Helmet>
        <title>
          {t("portfolio")} - {t("bestPhotos")}
        </title>
        <meta name="description" content={`${t("description")}`} />
        <link rel="canonical" href="/bestWork" />
      </Helmet>

      <Navbar />
      <div className="flex flex-col lg:flex-row items-center justify-between mt-4">
        <img
          src="/bestWork/bestWork1.webp"
          alt={t("imageAlt1")}
          width={430}
          height={350}
          className="object-cover pt-2 hidden lg:block mr-0 ml-20"
          title={`${t("portfolio")} Wooden Boat Photography`}
        />
        <div className="flex flex-col items-center justify-center text-2xl text-white lg:text-4xl mb-10 lg:mb-0 mr-0 lg:mr-20">
          <div className="flex items-center justify-center flex-col">
            <h1 className="uppercase font-light mt-9">{t("header")}</h1>
            <img
              src="/traka.png"
              alt="traka"
              width={350}
              height={100}
              className="flex items-center justify-center mt-2 lg:mb-4"
              title="Ukrasna traka"
              loading="eager"
            />
          </div>
          <div className="flex items-center flex-col justify-center space-y-3">
            <div className="flex flex-col items-center justify-center space-y-3 lg:flex-row gap-x-0 lg:gap-x-3">
              <img
                src="/10.webp"
                alt={`Pasara - ${t("boats")}`}
                width={280}
                height={500}
                className="h-[300px] lg:h-[200px] mt-4 lg:hidden"
                title={`Pasara - ${t("boats")}`}
              />
              <img
                src="/bestWork/bestWork2.webp"
                alt={`Riba - ${t("fishes")}`}
                width={300}
                height={500}
                className="h-[200px]"
                title={`Riba - ${t("fishes")}`}
              />
              <img
                src="/bestWork/bestWork3.webp"
                alt={`Bonaca - ${t("boats")}`}
                width={200}
                height={200}
                className="h-[200px]"
                title={`Bonaca - ${t("boats")}`}
              />
              <img
                src="/bestWork/bestWork4.webp"
                alt={`latinsko idro - ${t("boats")}`}
                width={300}
                height={500}
                className="h-[200px]"
                title={`latinsko idro - ${t("boats")}`}
              />
            </div>
            <div className="flex flex-col items-center justify-center space-y-3 lg:space-y-0 lg:flex-row gap-x-3">
              <img
                src="/bestWork/bestWork5.webp"
                alt={`na škveru - ${t("boats")}`}
                width={300}
                height={500}
                className="h-[200px]"
                title={`na škveru - ${t("boats")}`}
              />
              <img
                src="/bestWork/bestWork6.webp"
                alt={`na valu - ${t("boats")}`}
                width={200}
                height={500}
                className="h-[200px]"
                title={`na valu - ${t("boats")}`}
              />
              <img
                src="/bestWork/bestWork7.webp"
                alt={`falkuša - ${t("boats")}`}
                width={300}
                height={500}
                className="h-[200px]"
                title={`falkuša - ${t("boats")}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
