import { useState } from "react";

import i18next from "../i18n";

const LanguageChanger = () => {
  const [locale, setLocale] = useState(i18next.language);

  i18next.on("languageChanged", (lng) => setLocale(i18next.language));

  const handleChange = (newLocale) => {
    i18next.changeLanguage(newLocale);
  };

  return (
    <div className="inline-block relative space-x-4">
      <button onClick={() => handleChange("en")} disabled={locale === "en"}>
        <img src="/england.svg" alt="English" width={32} height={32} />
      </button>
      <button onClick={() => handleChange("hr")} disabled={locale === "hr"}>
        <img src="/hrvatska.svg" alt="Croatian" width={32} height={32} />
      </button>
    </div>
  );
};

export default LanguageChanger;
