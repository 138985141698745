import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import Close from "@mui/icons-material/Close";
import Button from "@mui/joy/Button";

const CarouselTwo = () => {
  const { t } = useTranslation("common");
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);

  const imageInfoArray = [
    {
      src: "/slika4.webp",
      date: "October 2020",
      name: "regata",
      width: 485,
      height: 330,
      xl: { width: 780, height: 400 },
    },
    {
      src: "/slika5.webp",
      date: "June 2022",
      name: "na valu",
      width: 370,
      height: 250,
      xl: { width: 590, height: 350 },
    },
    {
      src: "/slika6.webp",
      date: "July 2023",
      name: "vitar u krmu",
      width: 500,
      height: 350,
      xl: { width: 794, height: 400 },
    },
  ];

  const openFullscreen = (src) => {
    setFullscreenImage(src);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Postavi početnu vrijednost za širinu ekrana
    setWindowWidth(window.innerWidth);

    // Dodaj slušač za promjenu veličine ekrana
    window.addEventListener("resize", handleResize);

    // Ukloni slušač kada komponenta prestane biti montirana
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="relative lg:fixed">
      <Helmet>
        <title>{t("common:metaTitle")}</title>
        <meta name="description" content={t("common:metaDescription")} />{" "}
        <meta
          name="keywords"
          content="digitalna umjetnost, galerija slika, likovna umjetnost, moderna umjetnost, suvremena tradicionalna umjetnost, drveni brod, tradicionalni drveni brod, stari drveni brod, veslo, jedro, krma, trup broda, paluba, kormilo, ribarska mreža, obala, mornar, sjeverni vjetar, jugo, regata, tradicionalna regata, latinsko jedro, dalmacija, jadransko more, mediteran, južna europa, luka, tradicionalna brodogradnja, mali drveni brod, glavno jedro, jarbol, čvor, sidro, bova"
        />
        <meta name="author" content="Edo Opanovic" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <img
        src="/podloga1.webp"
        alt="Podloga"
        className="object-cover w-full lg:block hidden"
        height={720}
        width={1920}
      />
      <div className="flex flex-col items-center justify-between absolute top-0 left-0 right-0 mx-20 gap-x-8 lg:flex-row overflow-y-hidden">
        {imageInfoArray.map((imageInfo, index) => (
          <div
            key={index}
            className={`relative ${
              index === 0 || index === imageInfoArray.length - 1
                ? "py-11 lg:py-16"
                : "pl-0"
            }`}
          >
            <img
              src={imageInfo.src}
              alt={`Slika ${index + 1}`}
              width={
                windowWidth > 1536 && imageInfo.xl
                  ? imageInfo.xl.width
                  : imageInfo.width
              }
              height={
                windowWidth > 1536 && imageInfo.xl
                  ? imageInfo.xl.height
                  : imageInfo.height
              }
              className={
                "object-cover cursor-pointer drop-shadow-2xl shadow-black 2xl"
              }
              onClick={() => openFullscreen(imageInfo.src)}
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        ))}
      </div>

      {fullscreenImage && (
        <div
          className="fixed top-0 left-0 z-50 w-screen h-screen bg-black bg-opacity-100 flex items-center justify-center p-4"
          onClick={closeFullscreen}
        >
          <div className="relative flex flex-col items-center justify-center w-full h-full text-white lg:flex lg:flex-row">
            <div className="relative w-full lg:w-3/4 h-full max-w-[100vw] max-h-[100vh]">
              <img
                src={fullscreenImage}
                alt="Fullscreen"
                className="object-contain w-full h-full"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
            <div className="relative w-full lg:w-1/4 h-full p-4 flex flex-col items-center justify-center">
              {imageInfoArray.map((imageInfo, index) => {
                if (imageInfo.src === fullscreenImage) {
                  return (
                    <div key={index}>
                      <div className="absolute top-0 right-0 mt-4 mr-4 hidden lg:block">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={closeFullscreen}
                        >
                          <Close className="w-5 h-5" />
                        </Button>
                      </div>
                      <div className="mx-0 lg:mx-0 w-full 2xl:w-96 font-light">
                        <p className="mb-2 w-full lg:mb-1 2xl:ml-[-20px] text-sm 2xl:text-xl">
                          {t("name")}: <strong>{imageInfo.name}</strong>
                        </p>
                        <p className="mb-2 w-full lg:mb-1 2xl:ml-[-20px] text-sm 2xl:text-xl">
                          {t("date")}: <strong>{imageInfo.date}</strong>
                        </p>
                        <p className="mb-2 w-full lg:mb-1 2xl:ml-[-20px] text-sm 2xl:text-xl">
                          {t("price")}: <strong>{t("onRequest")}</strong>
                        </p>
                        <p className="mb-2 w-full lg:mb-1 2xl:ml-[-20px] text-sm 2xl:text-xl">
                          Mail:{" "}
                          <strong>
                            <a href="mailto:info@dalmatinske-vizure.com">
                              info@dalmatinske-vizure.com
                            </a>
                          </strong>
                        </p>
                        <p className="mb-2 w-full lg:mb-1 2xl:ml-[-20px] text-sm 2xl:text-xl">
                          Mail:{" "}
                          <strong>
                            <a href="mailto:info@dalmatinske-vizure.com">
                              info@fine-art-of-dalmatia.com
                            </a>
                          </strong>
                        </p>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarouselTwo;
