import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Navbar } from "../../components/Navbar";

import OtherCarouselOne from "./components/otherOne";
import OtherCarouselTwo from "./components/otherTwo";

const OtherImages = () => {
  const { t } = useTranslation("common");
  const [activeCarousel, setActiveCarousel] = useState("otherCarouselOne");
  const showArrowLeft = Boolean(activeCarousel !== "otherCarouselOne");
  const showArrowRight = Boolean(activeCarousel !== "otherCarouselTwo");

  const handleCarouselChange = (direction) => {
    setActiveCarousel((prevActive) => {
      const carouselMap = {
        otherCarouselOne:
          direction === "right" ? "otherCarouselTwo" : "otherCarouselOne",
        otherCarouselTwo:
          direction === "left" ? "otherCarouselOne" : "OtherCarouselTwo",
      };
      return carouselMap[prevActive];
    });
  };

  return (
    <div className="m-0 w-full z-20">
      <Helmet>
        <title>{t("common:otherTitle")}</title>
        <meta name="description" content={t("common:otherDescription")} />
        <meta
          name="keywords"
          content="kamena kuća, stara kamena kuća, tradicionalna kamena kuća, kameni zid, tradicionalni kameni zid, stari kameni zid, maslina, maslinjak, ribarska mreža, kamene stepenice, tradicionalne kamene stepenice, drveni prozor, tradicionalni drveni prozor, dalmacija, jadransko more, mediteran, stara drvena bačva, drvena bačva, kuće uz more, stare mediteranske kamene kuće, dalmatinska tradicionalna kamena kuća"
        />
        <meta
          name="keywords"
          content="
          stone house, old stone house, traditional stone house, stone wall, traditional stone wall, old stone wall, olive tree, olive grove, fish net, stone steps, traditional stone steps, wooden window, traditional wooden window, dalmatia, the adriatic sea, Mediterranean, old wooden barrel, wooden barrel, house by the sea, old mediterraen stone houses, traditional dalmatian stone house 
        "
        />
        <meta author="Edo Opanovic" />
        <meta robots="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="canonical"
          href="https://dalmatinske-vizure.com/gallery/other"
        />
      </Helmet>
      <Navbar />

      {/* Siva traka */}
      <div className="flex items-center justify-between mx-0 h-[44px] bg-[#656565] z-90">
        <h1 className="font-light text-2xl lg:ml-[90px] md:ml-10 ml-6 text-white">
          {t("gallery")}
        </h1>
        <div className="flex items-center justify-center gap-x-2.5 h-[60px] lg:mr-16 md:mr-8 mr-4 text-white">
          <h1 className="font-light text-white text-2xl">{t("other")}</h1>
          <div className="flex items-center justify-center gap-x-2.5 w-[1px] h-4 rotate-12 bg-gray-500"></div>
          <div className="flex items-center space-x-2.5">
            {showArrowLeft && (
              <ArrowBack
                className="cursor-pointer w-4 h-4"
                onClick={() => handleCarouselChange("left")}
              />
            )}
            {showArrowRight && (
              <ArrowForward
                className="cursor-pointer w-4 h-4"
                onClick={() => handleCarouselChange("right")}
              />
            )}
          </div>
        </div>
      </div>

      <div>
        {activeCarousel === "otherCarouselOne" && <OtherCarouselOne />}
        {activeCarousel === "otherCarouselTwo" && <OtherCarouselTwo />}
      </div>
    </div>
  );
};

export default OtherImages;
