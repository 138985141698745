import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Navbar } from "../../components/Navbar";

import FishCarouselOne from "./components/fishOne";

const FishImages = () => {
  const { t } = useTranslation("common");
  return (
    <div className="m-0 w-full">
      <Helmet>
        <title>{t("common:fishTitle")}</title>
        <meta name="description" content={t("common:fishDescription")} />
        <meta
          name="keywords"
          content="srdela, bijela riba, plava riba, tuna, dimljena riba, rak, školjka, podvodni život, ribarenje"
        />
        <meta
          name="keywords"
          content="sardine, white fish, blue fish, tuna, smoked fish, crab, shell, marine wildlife, fishing
        "
        />
        <meta name="author" content="Edo Opanovic" />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://dalmatinske-vizure.com/gallery/fish"
        />
      </Helmet>
      <Navbar />

      {/* Siva traka */}
      <div className="flex items-center justify-between mx-0 h-[44px] bg-[#656565]">
        <h1 className="font-light text-2xl lg:ml-[90px] md:ml-10 ml-6 text-white">
          {t("gallery")}
        </h1>
        <div className="flex items-center justify-center gap-x-2.5 h-[60px] lg:mr-16 md:mr-8 mr-4 text-white">
          <h1 className="font-light text-white text-2xl">{t("fish")}</h1>
        </div>
      </div>

      <FishCarouselOne />
    </div>
  );
};

export default FishImages;
